<template>
<div>
<div class="row">
  <spinner x-small v-if="loading"></spinner> 
  <div class="ml-10 mr-10" v-html="item.description">
  </div>
</div>
</div>
</template>

<script>
import i18n from '@/i18n';
export default {

  data () {
        return {
          item: {
            description: null
          },
          error: null,
          loading: false,
        }
  },
  metaInfo() {
    return {
      title: i18n.t('terms-conditions'),
      meta: [
        {
          name: 'description',
          content: i18n.t('description.terms_conditions')

        }
      ],
    }
  },
  mounted(){
    this.getTerms();
  },
  methods:{
      getTerms() {
      this.loading = true;
      let uri = '/api/terms';
      this.$http.get(uri)
          .then((response) => {
            this.item = response.data.data
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => this.loading = false);
    },
  }
 
}

</script>
